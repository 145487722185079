import './styled.css'

import mi4u_logo from '../../assets/images/logomi4u.png';
import linkDin_logo from '../../assets/images/linkedin.png';
import whatsApp_logo from '../../assets/images/whats.png';
import neuron from '../../assets/SVGs/neuron_x.svg'

export function Home() {

    return (
        <main>
            <div>
                <img
                    className='logo'
                    src={mi4u_logo}
                    alt="logo"
                />
                <div>
                    <p>
                        {` Transformando Dados em Inteligência Operacional 
                        através de Big Data, Inteligência Artificial e 
                        Assistentes Virtuais`}
                    </p>
                </div>
                <div>
                    <a
                        href="https://www.linkedin.com/company/mi4u"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className='trx'
                            src={linkDin_logo}
                            alt="logolinkedin"
                        />
                    </a>

                    <a
                        href="https://wa.me/551636009505?text=Estou%20vindo%20do%20site%20e%20gostaria%20de%20falar%20com%20a%20MI4U."
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            className='trx'
                            src={whatsApp_logo}
                            alt="logolinkedin"
                        />
                    </a>
                </div>
                <div>
                    <button onClick={() => { window.open("https://whats.mi4u.app/", "_blank"); }}>
                        {`Entrar`}
                    </button>
                </div>
            </div>
            <div className='footer'>
                <div className='powered'>
                    <span>{`Powered by`}</span>
                    <img
                        onClick={() => { window.open('https://neurondata.com.br/', "_blank") }}
                        src={neuron}
                        alt="neuron--" />
                    <span></span>
                    <span className='copyright'>
                        &#169;
                    </span>
                    <span>
                        {`${new Date().getFullYear()}`}
                    </span>
                </div>
            </div>
        </main>
    )
}


